<template>
    <div>
        <!-- <div class="form-row">
            <div class="form-col">
                <label for="name">apartment name</label>
                <input type="text" class="form-input" id="name"  v-model="apartment.name"/>
                <span v-if="hasError('apartment.name')" class="form-error">{{ showError('apartment.name') }}</span>
            </div>
            <div class="form-col">
                <label for="tagLine">tag line</label>
                <input type="text" class="form-input" id="tagLine"  v-model="apartment.tagLine"/>
            </div>
        </div> -->
        <div class="form-row">
            <div class="form-col">
                <div class="form-field">
                    <label class="mb-1">logo</label>
                    <file-manager
                        :main-directory="'cms'"
                        :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                        :libraryFilters="['marketing', 'cms']"
                        :max-nb-of-files="1"
                        :max-upload-size="20"
                        :cropper-options="{aspectRatio: 1, cropBoxResizable: true, zoomOnWheel: false}"
                        :noCropper="true"
                        v-model="apartment.logo"
                        @uploaded-to-s3="createWebsiteImage"
                    ></file-manager>
                    <span v-if="hasError('apartment.logo')" class="form-error">{{ showError('apartment.logo') }}</span>
                </div>

                <div class="form-field">
                    <label class="mb-1">show menu button</label>
                    <toggle-input v-model="apartment.showMenuButton" />
                </div>
            </div>
            <div class="form-col">
                <div class="form-field">
                    <label for="phoneNumber">phone number</label>
                    <the-mask
                        :data-test="apartment.phoneNumber"
                        id="phoneNumber"
                        type="text"
                        class="form-input"
                        mask="###-###-####"
                        v-model="apartment.phoneNumber"
                    />
                    <span v-if="hasError('apartment.phoneNumber')" class="form-error">{{ showError('apartment.phoneNumber') }}</span>
                </div>
                <div class="form-field">
                    <label for="email">email</label>
                    <text-input
                      id="email"
                      v-model="apartment.email"
                    />
                    <span v-if="hasError('apartment.email')" class="form-error">{{ showError('apartment.email') }}</span>
                </div>
                <div class="form-field">
                    <label>apply link</label>
                    <url-input v-model="apartment.applyLink" :website-id="website.id"></url-input>
                </div>
                <div class="form-field">
                    <label>tour link</label>
                    <url-input v-model="apartment.tourLink" :website-id="website.id"></url-input>
                </div>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import ToggleInput from "@/components/ui/ToggleInput";
    import UrlInput from "@/components/cms/UrlInput";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";
    import TextInput from "@/components/ui/TextInput";
    import {TheMask} from 'vue-the-mask';

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter, ToggleInput, UrlInput, FileManager, TextInput, TheMask },
        emits: ['save-page-widget'],
        data: () => {
            return {
                apartment: {
                    //name: null,
                    //tagLine: null,
                    phoneNumber: null,
                    email: null,
                    showMenuButton: false,
                    applyLink: null,
                    tourLink: null,
                    logo: []
                },
                websiteId: {},
                property: {},
                isProcessing: false,
            }
        },
        computed: {
            ...mapGetters({
                getPropertyById: 'properties/getPropertyById',
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            })
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    let payload = Object.assign({}, this.apartment);
                    payload.logo = this.getThumbnailUrlByFileId(this.apartment.logo[0]);
                    this.isProcessing = true;
                    this.$emit('save-page-widget', payload);
                }
            },
        },
        validations: {
            //'apartment.name' : 'required',
            'apartment.email' : 'required | email',
            'apartment.phoneNumber' : 'required',
            'apartment.logo' : 'required',
        },
        async created() {
            this.website = this.getWebsiteInfo
            this.initValidator();
            this.property = this.getPropertyById(this.website.communities_id);

            this.apartment.phoneNumber = this.website.contactInformation.contact_phone;
            this.apartment.email = this.website.contactInformation.contact_email;

            if (this.values.length) {
                Object.assign(this.apartment, {
                    //name: this.getValue('name'),
                    //tagLine: this.getValue('tagLine'),
                    phoneNumber: this.getValue('phoneNumber'),
                    email: this.getValue('email'),
                    showMenuButton: this.getValue('showMenuButton'),
                    applyLink: this.getValue('applyLink'),
                    tourLink: this.getValue('tourLink'),
                    logo: this.getValue('logo') ? [ this.getFileIdByThumbnailUrl( this.getValue('logo') ) ] : [],
                });
            }
        },
    }
</script>
